import { Button, SxProps } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Text } from './Text';

interface AddButtonProps {
  items: unknown[];
  addAction: () => void;
  text: string;
}

export const AddButton: React.FC<AddButtonProps> = ({ items, addAction, text }) => {
  const theme = useTheme();
  const hasItems = items.length > 0;

  return (
    <Button
      onClick={addAction}
      sx={{
        ...addBoxStyle,
        borderRadius: hasItems ? '0 0 10px 10px' : '10px',
        background: theme.palette.background.default
      }}
    >
      <AddCircleOutlineRoundedIcon style={{ color: theme.palette.text.primary }} />
      <Text textTransform="capitalize">{text}</Text>
    </Button>
  );
};

const addBoxStyle: SxProps = {
  width: '100%',
  backgroundPosition: '50%',
  backdropFilter: 'blur(20px)',
  display: 'flex',
  cursor: 'pointer',
  padding: '14px',
  gap: '7px',
  marginTop: '3px',
  justifyContent: 'flex-start'
};
