import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { Text } from '../../Text';
import { ContextMenu } from './ContextMenu';

interface BookmarkItemProps {
  title: string;
  url: string;
  id: string;
  handleDeleteBookmarkOrFolder: (id: string) => void;
  handleOpenUpdateDialog: (id: string) => void;
}

const bookmarkSize = 47;

export const BookmarkItem: FC<BookmarkItemProps> = ({
  title,
  url,
  id,
  handleDeleteBookmarkOrFolder,
  handleOpenUpdateDialog
}) => {
  const shortText = (title: string) => {
    if (title.length <= 30) {
      return title;
    } else {
      return title.substring(0, 30) + '...';
    }
  };

  return (
    <ContextMenu
      handleDeleteBookmarkOrFolder={handleDeleteBookmarkOrFolder}
      id={id}
      handleOpenUpdateDialog={handleOpenUpdateDialog}
    >
      <Button href={url}>
        <Stack textAlign="center" alignItems="center">
          <Stack
            sx={{
              boxShadow: 1,
              borderRadius: '15px',
              backgroundColor: 'common.white',
              width: bookmarkSize,
              height: bookmarkSize,
              p: '4px',
              mb: '8px'
            }}
          >
            <Stack
              sx={{
                backgroundImage: `url(https://www.google.com/s2/favicons?domain=${url}&sz=256)`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100%'
              }}
            />
          </Stack>
          <Text fontSize={10} textTransform="capitalize">
            {shortText(title)}
          </Text>
        </Stack>
      </Button>
    </ContextMenu>
  );
};
