import React, { useContext, useState } from 'react';
import { List, ListItem, Stack } from '@mui/material';
import { ReactComponent as Apps } from './assets/apps.svg';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import HistoryIcon from '@mui/icons-material/History';
import { ReactComponent as Downloads } from './assets/download.svg';
import { ReactComponent as Settings } from './assets/settings.svg';
import { ServiceButton } from './ServiceButton';
import { AppsMenu } from './AppsMenu';
import { SettingsModal } from '../../settings/SettingsModal';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../hooks/GlobalContext';

export interface PanelItems {
  icon: React.ReactElement;
  action: string;
  label: string;
}

const dataType = 'action-type-message';

export const ServicePanel = () => {
  const { t } = useTranslation();
  const { openSettingModal, setOpenSettingModal } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenSettings = () => {
    setOpenSettingModal(prevState => !prevState);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: PanelItems['action']) => {
    window.postMessage({ type: dataType, action: action }, '*');
  };

  const panelItems: PanelItems[] = [
    {
      icon: <Apps />,
      action: 'manageApps',
      label: t('servicePanel:apps')
    },
    {
      icon: <BookmarkBorderIcon />,
      action: 'manageOpenBookmarks',
      label: t('servicePanel:bookmarks')
    },
    {
      icon: <HistoryIcon />,
      action: 'manageOpenHistory',
      label: t('servicePanel:history')
    },
    {
      icon: <Downloads />,
      action: 'manageOpenDownloads',
      label: t('servicePanel:downloads')
    },
    {
      icon: <Settings />,
      action: 'manageOpenSettings',
      label: t('servicePanel:settings')
    }
  ];

  return (
    <Stack>
      <List sx={{ display: 'inline-flex', flexDirection: 'column', p: 0, gap: '22px' }}>
        {panelItems.map(({ icon, action, label }) => {
          const actionTypes: { [key: string]: (event: React.MouseEvent<HTMLButtonElement>) => void } = {
            manageApps: handleOpenMenu,
            manageOpenBookmarks: () => handleAction(action),
            manageOpenHistory: () => handleAction(action),
            manageOpenDownloads: () => handleAction(action),
            manageOpenSettings: handleOpenSettings
          };

          return (
            <ListItem
              sx={{
                borderRadius: '8px',
                p: 0,
                '&:hover': { backgroundColor: 'action.hover' }
              }}
              key={action}
            >
              <ServiceButton
                onClick={actionTypes[action]}
                icon={icon}
                handleAction={handleAction}
                handleOpenMenu={handleOpenMenu}
                label={label}
              />
            </ListItem>
          );
        })}
      </List>
      <AppsMenu anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} isOpen={isOpen} />
      <SettingsModal open={openSettingModal} setOpen={setOpenSettingModal} />
    </Stack>
  );
};
