import { manifest } from '../manifest';

export interface ApplicationData {
  firstOffer: FirstOffer;
}

export interface FirstOffer {
  widgets: Widget[];
  backgroundImages: BackgroundImages[];
  language: string;
  baseURL?: string;
}
export interface Widget {
  key: string;
  config: Config;
  name: string;
  shouldShow: boolean;
}
interface Config {
  locationPanel?: string;
  sites?: Site[];
  unit?: string;
  default?: string;
  country?: string | null;
  data?: string[] | null;
  backgroundColor?: string;
  color?: string;
  hour12?: boolean;
  symbol?: string[];
  fontFamily?: string;
}
export interface Site {
  name: string;
  icon?: string;
  url: string;
}
export interface BackgroundImages {
  url: string;
  themeMode: string;
  isDefault: boolean;
}
const getManifestData = () => manifest;

export { getManifestData };
