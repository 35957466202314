import React, { useContext } from 'react';
import {  ButtonBase, Stack } from '@mui/material';
import { BackgroundImages } from '../../services/manifest.service';
import { GlobalContext } from '../../hooks/GlobalContext';
import { useTheme } from '@mui/material/styles';

interface BulletsProps {
  onBulletClick: (background: BackgroundImages) => void;
  currentBackground: BackgroundImages | null;
}

export const Bullets: React.FC<BulletsProps> = ({ onBulletClick, currentBackground }) => {
  const { backgrounds } = useContext(GlobalContext);
  const theme = useTheme();

  if (!backgrounds) return null
  return (
    <Stack direction='row' justifyContent= 'center' gap= '6px' sx={{   mt: 'auto' }}>
      {backgrounds?.map((backgroundData, index) => {
        const isSelected = backgroundData.url === currentBackground?.url;
        return (
          <ButtonBase
            onClick={() => {
              onBulletClick(backgrounds[index]);
            }}
            key={backgroundData.url}
            sx={{
              minWidth: '14px',
              minHeight: '14px',
              borderRadius: '50px',
              background: isSelected?theme.bullets.selected:theme.bullets.main
               
            }}
          />
        );
      })}
    </Stack >
  );
};
