import React, { FC } from 'react';
import { List, ListItem } from '@mui/material';
import { BookmarksProps } from './Bookmarks';
import { BookmarkItem } from './BookmarkItem';
import BookmarksFolder from './BookmarksFolder';

interface BookmarksListProps {
  myBookmarks: BookmarksProps['bookmarks'];
  handleFolder: (title: string) => void;
  handleDeleteBookmarkOrFolder: (id: string) => void;
  handleOpenUpdateDialog: (id: string) => void;
}

export const BookmarksList: FC<BookmarksListProps> = ({
  myBookmarks,
  handleFolder,
  handleDeleteBookmarkOrFolder,
  handleOpenUpdateDialog
}) => (
  <List
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 90px)',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      gap: '16px 0',
      py: 0
    }}
  >
    {myBookmarks.map(({ title, url, id }) => (
      <ListItem key={title} sx={{ p: 0, width: 'auto', justifyContent: 'center' }}>
        {url ? (
          <BookmarkItem
            handleOpenUpdateDialog={handleOpenUpdateDialog}
            title={title}
            url={url}
            handleDeleteBookmarkOrFolder={handleDeleteBookmarkOrFolder}
            id={id}
          />
        ) : (
          <BookmarksFolder
            title={title}
            handleFolder={handleFolder}
            handleDeleteBookmarkOrFolder={handleDeleteBookmarkOrFolder}
            id={id}
            handleOpenUpdateDialog={handleOpenUpdateDialog}
          />
        )}
      </ListItem>
    ))}
  </List>
);

BookmarksList;
