import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Switch } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { Text } from '../../Text';
import { useTranslation } from 'react-i18next';
import { SearchTab } from './SearchTab';
import { useTheme } from '@mui/material/styles';

export interface PreferencesProps {
  is12HFormat: boolean | undefined;
}
export const Preferences: React.FC<PreferencesProps> = ({ is12HFormat }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const SEARCH = 'search';

  const {
    setShouldShow,
    findSpecificWidget,
    showToggleItems,
    widgets,
    findSpecificWidgetIndex,
    saveWidgetToLocalStorage
  } = useContext(GlobalContext);

  const sites = useMemo(() => {
    const [searchWidget] = widgets.filter(({ key }) => key === SEARCH);
    if (!searchWidget) return [];
    return searchWidget.config.sites;
  }, [widgets]);

  const selectedSite = useMemo(() => {
    const [searchWidget] = widgets.filter(({ key }) => key === SEARCH);
    if (!searchWidget || !sites) return;
    const selectedSiteName = searchWidget.config.default;
    const [result] = sites.filter(item => item.name === selectedSiteName);
    return result;
  }, [widgets]);

  const shouldShow = (widgetName: string) => {
    const result = findSpecificWidget(widgetName);
    return result?.shouldShow;
  };
  const handleFormat = (event: SelectChangeEvent<string>) => {
    const result = event.target.value === '12';
    const currentWidgets = [...widgets];
    const index = findSpecificWidgetIndex('date');
    if (index === undefined) return;
    currentWidgets[index].config.hour12 = result;
    saveWidgetToLocalStorage(currentWidgets);
  };
  return (
    <Stack sx={{ p: 0 }}>
      {showToggleItems.map(item => (
        <Stack key={item.key} direction="row" alignItems="center" sx={{ justifyContent: 'space-between', p: 0 }}>
          <Text color={theme.customColors.setting}> {t(`settings:${item.text}`)} </Text>
          <Stack direction="row" alignItems="center">
            <Switch
              checked={shouldShow(item.key)}
              onChange={() => {
                setShouldShow(item.key, shouldShow(item.key));
              }}
            />
          </Stack>
        </Stack>
      ))}
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between' }}>
        <Text color={theme.customColors.setting}>{t('time_format', { ns: 'settings' })}</Text>
        <FormControl>
          <Select
            variant="standard"
            onChange={handleFormat}
            value={is12HFormat ? '12' : '24'}
            sx={{ '&:before': { borderBottom: 'unset' } }}
            inputProps={{
              name: 'time_format',
              id: 'uncontrolled-native'
            }}
          >
            <MenuItem value="24">
              <Text color={theme.customColors.setting}>24-Hour</Text>
            </MenuItem>
            <MenuItem value="12">
              <Text color={theme.customColors.setting}>12-Hour</Text>
            </MenuItem>
          </Select>
          <Stack />
        </FormControl>
      </Stack>
      <SearchTab sites={sites} selectedSite={selectedSite} />
    </Stack>
  );
};
