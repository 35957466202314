import React, { useContext, useMemo, useState } from 'react';
import { ThemeProvider } from '../theme';
import { SearchBar } from './widget/SearchBar/SearchBar';
import { Panel } from './Panel';
import { Stack, SxProps } from '@mui/material';
import { BackgroundImages } from '../services/manifest.service';
import { DateAndTime } from './widget/DateAndTime';
import { ServicePanel } from './widget/ServicePanel/ServicePanel';
import { Bullets } from './widget/Bullets';
import { PopularSites } from './widget/PopularSites';
import { Bookmarks } from './widget/Bookmarks/Bookmarks';
import { GlobalContext } from '../hooks/GlobalContext';
import { localStorageService } from '../services/storage.service';
export enum PanelSide {
  left = 'left',
  right = 'right'
}
export enum DefaultOption {
  search = 'search',
  date = 'date'
}
const BOOKMARKS = 'bookmarks';
const POPULAR_SITE = 'popularSite';

export enum StorageKeys {
  background = 'background',
  timeFormat = 'time-format',
  site = 'site',
  notes = 'notes',
  weather = 'weather',
  todo = 'todo',
  stock = 'stock',
  widgets = 'widget'
}
const DATE = 'date';

const getDefaultBackground = (backgrounds: BackgroundImages[]) => {
  const defaultBackground = localStorageService.get(StorageKeys.background);
  const result = backgrounds.find(({ isDefault }) => isDefault);
  if (defaultBackground) return defaultBackground;
  else if (result) {
    localStorageService.set(StorageKeys.background, result);
    return result;
  }
};
export const Container: React.FC = () => {
  const SEARCH = 'search';
  const { backgrounds, findSpecificWidget, widgets } = useContext(GlobalContext);
  const [currentBackground, setCurrentBackground] = useState<BackgroundImages>(getDefaultBackground(backgrounds));

  const onBulletClick = (currentBackground: BackgroundImages) => {
    setCurrentBackground(currentBackground);
    localStorageService.set(StorageKeys.background, currentBackground);
  };
  const shouldShowPopularSites = findSpecificWidget(POPULAR_SITE)?.shouldShow;
  const shouldShowBookmarks = findSpecificWidget(BOOKMARKS)?.shouldShow;

  const widgetsForLeftPanel = widgets.filter(widget => widget.config.locationPanel === PanelSide.left);
  const widgetsForRightPanel = widgets.filter(widget => widget.config.locationPanel === PanelSide.right);

  const showLeftPanel = widgetsForLeftPanel.length !== 0;
  const showRightPanel = widgetsForRightPanel.length !== 0;

  const sites = useMemo(() => {
    const [searchWidget] = widgets.filter(({ key }) => key === SEARCH);
    if (!searchWidget) return [];
    return searchWidget.config.sites;
  }, [widgets]);

  const selectedSite = useMemo(() => {
    const [searchWidget] = widgets.filter(({ key }) => key === SEARCH);
    if (!searchWidget || !sites) return;
    const selectedSiteName = searchWidget.config.default;
    const [result] = sites.filter(item => item.name === selectedSiteName);
    return result;
  }, [widgets]);
  const is12HFormat = useMemo(() => {
    const weatherWidget = widgets.find(item => item.key === DATE);
    if (!weatherWidget) return;
    return weatherWidget.config.hour12;
  }, [widgets]);
  return (
    <ThemeProvider themeMode={currentBackground.themeMode}>
      <Stack
        sx={{
          ...container,
          backgroundImage: `url(${currentBackground.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          height: '100vh'
        }}
      >
        <Stack sx={main}>
          <DateAndTime is12HFormat={is12HFormat} />
          <Stack direction="row">
            <Stack direction="row" gap="40px" sx={!widgetsForLeftPanel.length ? { pr: '255px' } : {}}>
              <ServicePanel />
              {showLeftPanel && <Panel widgets={widgetsForLeftPanel} />}
            </Stack>
            <Stack sx={{ width: 576, gap: '60px', mx: '12px', marginInline: 'auto' }}>
              {selectedSite && sites && <SearchBar selectedSite={selectedSite} sites={sites} />}
              <Stack flexDirection="row" justifyContent="space-between" gap="0 48px">
                {shouldShowPopularSites && <PopularSites />}
                {shouldShowBookmarks && <Bookmarks />}
              </Stack>
            </Stack>
            <Stack sx={{ width: '300px', position: 'relative', marginLeft: '80px' }}>
              {showRightPanel && <Panel widgets={widgetsForRightPanel} />}
            </Stack>
          </Stack>
          <Bullets currentBackground={currentBackground} onBulletClick={onBulletClick} />
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

const main: SxProps = {
  pt: '114px',
  pb: '84px',
  px: '50px',
  flex: '1',
  gap: '2px',
  height: '100%'
};
const container: SxProps = {
  overflow: 'overlay',
  paddingInline: '15px'
};
