import React, { FC } from 'react';
import { Button, Menu, MenuItem, Stack } from '@mui/material';
import { appsManifest } from './appsManifest';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Text } from '../../Text';

interface AppsMenuProps {
  anchorEl: null | HTMLElement;
  handleCloseMenu: () => void;
  isOpen: boolean;
}

export const AppsMenu: FC<AppsMenuProps> = ({ anchorEl, isOpen, handleCloseMenu }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleCloseMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        sx: { py: 0, overflowY: 'auto' }
      }}
      PaperProps={{
        sx: {
          p: '24px',
          width: 320,
          height: 452,
          backdropFilter: 'blur(20px)',
          background: theme.customBackground.appsMenu,
          borderRadius: '15px'
        }
      }}
      sx={{ textAlign: 'center' }}
    >
      {appsManifest.map((appsList, i) => (
        <Stack
          key={`list-${i}`}
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            borderBottom: `1px solid ${theme.palette.text.secondary}`,
            py: '24px',
            '&:first-of-type': { pt: 0 },
            '&:last-of-type': { borderBottom: 'unset', pb: 0 }
          }}
          gap="16px"
        >
          {appsList.map(({ icon, label, url }) => (
            <MenuItem
              key={label}
              sx={{
                p: 0,
                justifyContent: 'center',
                '&:hover': { backgroundColor: 'transparent' }
              }}
            >
              <Stack>
                <Button
                  href={url}
                  sx={{
                    flexDirection: 'column',
                    p: 0,
                    width: 'auto',
                    minWidth: 'auto',
                    border: '1px solid transparent',
                    borderRadius: '2px',
                    '&:hover': {
                      textDecoration: 'unset',
                      backgroundColor: 'transparent',
                      border: `1px solid ${theme.palette.text.secondary}`
                    }
                  }}
                >
                  <img src={icon} alt={label} style={{ objectFit: 'contain' }} />
                  <Text fontSize={13} sx={{ textTransform: 'capitalize' }}>
                    {t(`appsButtons:${label}`)}
                  </Text>
                </Button>
              </Stack>
            </MenuItem>
          ))}
        </Stack>
      ))}
      <Button
        sx={{ p: 0, width: 'auto', minWidth: 'auto', fontSize: 13, textTransform: 'capitalize', mt: '24px' }}
        href="https://www.google.com/intl/en/about/products"
      >
        {t('appsMenu:moreButton')}
      </Button>
    </Menu>
  );
};
