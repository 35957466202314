import { Button, Stack, Switch } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { Text } from '../../Text';
import { useTranslation } from 'react-i18next';
import { Options } from '../../Panel';
import { localStorageService } from '../../../services/storage.service';
import { StorageKeys } from '../../Container';
import { useTheme } from '@mui/material/styles';

export const NoteTab: React.FC = () => {
  const { t } = useTranslation();

  const { setShouldShow, findSpecificWidget, setNotes } = useContext(GlobalContext);
  const shouldShow = findSpecificWidget(Options.notes)?.shouldShow;
  const theme = useTheme();

  const handleShow = () => {
    setShouldShow(Options.notes, shouldShow);
  };

  const removeAllNotes = () => {
    setNotes([]);
    localStorageService.set(StorageKeys.notes, []);
  };
  return (
    <Stack>
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', p: 0 }}>
        <Text color={theme.customColors.setting}>{t('settings:show_notes')}</Text>
        <Stack direction="row" alignItems="center">
          <Switch checked={shouldShow} onChange={handleShow} />
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: '15px', alignItems: 'center', justifyContent: 'space-between', p: 0 }}>
        <Text color={theme.customColors.setting}>{t('settings:delete_all_notes')}</Text>
        <Button
          variant="text"
          sx={{ textTransform: 'capitalize', color: theme.customColors.setting }}
          onClick={removeAllNotes}
        >
          {t('settings:delete')}
        </Button>
      </Stack>
    </Stack>
  );
};
