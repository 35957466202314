import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Switch } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { Unit } from 'openweathermap-ts/dist/types';
import { Text } from '../../Text';

import { Options } from '../../Panel';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface WeatherTabProps {
  temperatureUnit: Unit | undefined;
}
export const WeatherTab: React.FC<WeatherTabProps> = ({ temperatureUnit }) => {
  const { widgets, saveWidgetToLocalStorage, setShouldShow, findSpecificWidget, findSpecificWidgetIndex } =
    useContext(GlobalContext);
  const { t } = useTranslation();
  const shouldShow = findSpecificWidget(Options.weather)?.shouldShow;
  const theme = useTheme();

  const handleShow = () => {
    setShouldShow(Options.weather, shouldShow);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const index = findSpecificWidgetIndex(Options.weather);
    if (index === undefined) return;
    widgets[index].config.unit = event.target.value;
    saveWidgetToLocalStorage(widgets);
  };

  if (!temperatureUnit) return <></>;
  return (
    <Stack>
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', p: 0 }}>
        <Text color={theme.customColors.setting}>{t('settings:temperature_unit')}</Text>
        <FormControl>
          <Select
            variant="standard"
            inputProps={{
              name: 'temperature Unit',
              id: 'uncontrolled-native'
            }}
            value={temperatureUnit}
            onChange={handleChange}
          >
            <MenuItem value="metric">
              <Text color={theme.customColors.setting}>C</Text>
            </MenuItem>
            <MenuItem value="imperial">
              <Text color={theme.customColors.setting}>F</Text>
            </MenuItem>
          </Select>
          <Stack />
        </FormControl>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', p: 0 }}>
        <Text color={theme.customColors.setting}>{t('settings:show_weather')}</Text>
        <Stack direction="row" alignItems="center">
          <Switch checked={shouldShow} onChange={handleShow} />
        </Stack>
      </Stack>
    </Stack>
  );
};
