import { ApplicationData } from './services/manifest.service';

export const manifest: ApplicationData = {
  firstOffer: {
    baseURL: 'https://my-start-page.com',
    language: 'en',
    widgets: [
      {
        name: 'Bookmarks',
        key: 'bookmarks',
        config: {},
        shouldShow: true
      },
      {
        name: 'PopularSite',
        key: 'popularSite',
        config: {},
        shouldShow: true
      },
      {
        name: 'Weather',
        key: 'weather',
        config: {
          unit: 'metric',
          locationPanel: 'left'
        },
        shouldShow: true
      },
      {
        name: 'Stock Market',
        key: 'stock',
        config: {
          symbol: ['WIX', 'SPY', 'AMZN', 'MSFT', 'NVDA', 'AAPL', 'TSLA', 'GOOG'],
          locationPanel: 'right'
        },
        shouldShow: true
      },
      {
        name: 'Quotes',
        key: 'quotes',
        config: {
          fontFamily: 'Arial',
          locationPanel: 'left'
        },
        shouldShow: true
      },

      {
        name: 'Date and Time',
        key: 'date',
        config: {
          hour12: true
        },
        shouldShow: true
      },
      {
        name: 'Notes',
        key: 'notes',
        config: {
          locationPanel: 'right'
        },
        shouldShow: true
      },
      {
        name: 'Todo',
        key: 'todo',
        config: {
          locationPanel: 'right'
        },
        shouldShow: true
      },
      {
        name: 'News feed',
        key: 'news',
        config: {
          locationPanel: 'right'
        },
        shouldShow: true
      },
      {
        name: 'Search panel',
        key: 'search',
        config: {
          sites: [
            {
              name: 'google',
              icon: './assets/google.png',
              url: 'https://my-start-page.com/search.aspx?stype=google&partid=mstartp&q='
            },
            {
              name: 'bing',
              icon: './assets/bing.png',
              url: 'https://my-start-page.com/search.aspx?stype=bing&partid=mstartp&q='
            },
            {
              name: 'yahoo',
              icon: './assets/yahoo.png',
              url: 'https://my-start-page.com/search.aspx?stype=yahoo&partid=mstartp&q='
            }
          ],
          default: 'bing'
        },
        shouldShow: true
      }
    ],
    backgroundImages: [
      { url: '/backgroundImages/lightMode2.jpg', themeMode: 'light', isDefault: true },
      { url: '/backgroundImages/lightMode.jpg', themeMode: 'light', isDefault: false },
      { url: '/backgroundImages/darkMode1.jpg', themeMode: 'dark', isDefault: false }
    ]
  }
};
