import React, { ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { themeConfig } from './themeConfig';

type ThemeProviderProps = {
  children: ReactNode;
  themeMode: string;
};

export interface FontSize {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  xxxl: string;
  xxxxl: string;
}

export interface FontWeight {
  low: number;
  light: number;
  medium: number;
  bold: number;
}

export interface TextType {
  logo: string;
  default: string;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, themeMode }) => {
  const theme = createTheme(themeConfig[themeMode]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
