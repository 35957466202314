import React, { useContext, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Box, Button, CircularProgress, InputAdornment, Stack, SxProps, TextField } from '@mui/material';
import { Site } from '../../../services/manifest.service';
import GoogleIcon from './assets/google.png';
import BingIcon from './assets/bing.png';
import YahooIcon from './assets/yahoo.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import { Text } from '../../Text';
import { GlobalContext } from '../../../hooks/GlobalContext';

export enum SearchUrlBase {
  bing = 'bing',
  yahoo = 'yahoo',
  google = 'google'
}
interface SearchBarProps {
  selectedSite: Site;
  sites: Site[];
}

export const SearchBar: React.FC<SearchBarProps> = ({ selectedSite, sites }) => {
  const [search, setSearch] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { findSpecificWidgetIndex, saveWidgetToLocalStorage, widgets } = useContext(GlobalContext);

  const open = Boolean(anchorEl);
  const theme = useTheme();

  const iconSites = {
    [SearchUrlBase.google]: GoogleIcon,
    [SearchUrlBase.bing]: BingIcon,
    [SearchUrlBase.yahoo]: YahooIcon
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSite = (option: Site) => {
    const currentWidgetIndex = findSpecificWidgetIndex('search');
    if (currentWidgetIndex === undefined) return;
    widgets[currentWidgetIndex].config.default = option.name;
    saveWidgetToLocalStorage(widgets);
    handleClose();
  };
  const handleChange = (value: string) => {
    const searchType = value.split(' ').join('+');
    setSearch(searchType);
  };

  const onSearch = (event: React.FormEvent | React.MouseEvent) => {
    event.preventDefault();
    if (!selectedSite) return;
    const searchUrl = selectedSite.url + search;
    if (typeof window !== 'undefined') window.location.href = searchUrl;
  };

  const isLoading = !selectedSite;

  if (isLoading) return <CircularProgress />;

  return (
    <Stack sx={{ ...continuerSearch, background: theme.customBackground.searchBar }}>
      <Button
        sx={iconSearch}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        color="secondary"
      >
        {sites.map((site, index) => {
          const isSelected = site.name === selectedSite?.name;

          return (
            <MenuItem color="secondary" key={index} selected={isSelected} onClick={() => handleSite(site)}>
              <Stack direction="row" gap="7px">
                <img src={iconSites[site.name as SearchUrlBase]} width={24} height={24} alt="icon search" />
                <Text sx={{ textTransform: 'uppercase' }}>{site.name}</Text>
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
      <Box component="form" onSubmit={onSearch} style={{ width: '100%', position: 'relative' }}>
        <TextField
          onChange={event => {
            handleChange(event.target.value);
          }}
          type="text"
          sx={textField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="text"
                  onClick={onSearch}
                  sx={{ p: '7px', width: 'auto', minWidth: 'auto', borderRadius: '99999px' }}
                >
                  <SearchIcon sx={{ color: 'primary.main' }} />
                </Button>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Stack>
  );
};

const continuerSearch: SxProps = {
  py: '2px',
  px: '8px',
  borderRadius: '160px',
  display: 'flex',
  boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)',
  alignItems: 'center',
  position: 'relative',
  flexDirection: 'row'
};

const iconSearch: SxProps = {
  border: 'none',
  borderRadius: '999999px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  p: '7px',
  minWidth: 0,
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.08)'
  }
};

const textField: SxProps = {
  border: 'none',
  width: '100%',
  height: '100%',
  outline: 'none',
  position: 'relative',
  background: 'none',
  '& .MuiInputBase-root': { pr: 0 },

  '& .MuiOutlinedInput-input': {
    py: 0,
    px: '8px',

    color: 'primary.main'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
};
