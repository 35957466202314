import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { Text } from '../../Text';
import { useTheme } from '@mui/material/styles';
import { ContextMenu } from './ContextMenu';

interface BookmarksFolderProps {
  title: string;
  handleFolder: (title: string) => void;
  handleDeleteBookmarkOrFolder: (id: string) => void;
  id: string;
  handleOpenUpdateDialog: (id: string) => void;
}

const bookmarkFolderSize = 47;
const limit = 30;

const BookmarksFolder: FC<BookmarksFolderProps> = ({
  title,
  handleFolder,
  id,
  handleDeleteBookmarkOrFolder,
  handleOpenUpdateDialog
}) => {
  const theme = useTheme();

  const shortText = (title: string) => {
    if (title.length <= limit) {
      return title;
    } else {
      return title.substring(0, limit) + '...';
    }
  };

  return (
    <ContextMenu
      handleDeleteBookmarkOrFolder={handleDeleteBookmarkOrFolder}
      handleOpenUpdateDialog={handleOpenUpdateDialog}
      id={id}
    >
      <Button onClick={() => handleFolder(title)}>
        <Stack textAlign="center" alignItems="center">
          <Stack
            sx={{
              background: theme.customBackground.folder,
              fontSize: 24,
              width: bookmarkFolderSize,
              height: bookmarkFolderSize,
              borderRadius: '8px',
              boxShadow: 1,
              justifyContent: 'center',
              alignItems: 'center',
              mb: '8px'
            }}
          >
            <FolderIcon fontSize="large" />
          </Stack>
          <Text fontSize={10} textTransform="capitalize">
            {shortText(title)}
          </Text>
        </Stack>
      </Button>
    </ContextMenu>
  );
};

export default BookmarksFolder;
