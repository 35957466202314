import React, { FC, useEffect, useState } from 'react';
import { CircularProgress, Stack, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Text } from '../../Text';
import { QuotesService } from '../../../services/quotes.service';
import { QuoteButton } from './QuoteButton';

export interface Quotes {
  text: string;
  author: string;
}

interface SelectedQuotes extends Quotes {
  index: number;
}

export enum Action {
  next = 'next',
  prev = 'prev'
}

interface QuotesWidgetProps {
  fontFamily?: string;
}

export const QuotesWidget: FC<QuotesWidgetProps> = ({ fontFamily }) => {
  const [selectedQuote, setSelectedQuote] = useState<SelectedQuotes | null>(null);
  const [quotes, setQuotes] = useState<Quotes[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const theme = useTheme();

  const changeQuotes = (action: Action) => {
    if (!quotes || !selectedQuote) return;
    const num = action === 'prev' ? -1 : 1;
    const currentIndex = selectedQuote.index + num;
    setSelectedQuote({ ...quotes[currentIndex], index: currentIndex });
  };

  const getQuotesData = async () => {
    try {
      const result = await QuotesService.getQuotes();
      setQuotes(result);
      const rand = Math.floor(Math.random() * result.length);
      setSelectedQuote({ ...result[rand], index: rand });
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQuotesData();
  }, []);
  if (isLoading) return <CircularProgress color="secondary" />;
  if (!quotes || !selectedQuote) return <Stack>{t('common:no_have_results')}</Stack>;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="20px"
      sx={{ ...quotesStyle, background: theme.palette.background.default }}
    >
      <QuoteButton action={Action.prev} changeQuotes={changeQuotes} />
      <Stack>
        <Text
          sx={{
            textAlign: 'left',
            paddingTop: '33px',
            fontFamily: fontFamily ?? theme.typography.fontFamily
          }}
        >{`“${selectedQuote.text}”`}</Text>
        <Text
          weight="light"
          sx={{ textAlign: 'right', marginTop: '10px', fontFamily: fontFamily ?? theme.typography.fontFamily }}
        >
          {selectedQuote.author}
        </Text>
      </Stack>

      <QuoteButton action={Action.next} changeQuotes={changeQuotes} />
    </Stack>
  );
};

const quotesStyle: SxProps = {
  resize: 'none',
  width: '100%',
  border: 'none',
  position: 'relative',
  backdropFilter: 'blur(20px)',
  borderRadius: '15px',
  paddingInline: '22px',
  paddingBottom: '10px'
};
