import { FormGroup, Stack, Switch } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { Text } from '../../Text';
import { Options } from '../../Panel';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

export const NewsTab: React.FC = () => {
  const { setShouldShow, findSpecificWidget } = useContext(GlobalContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const shouldShow = findSpecificWidget(Options.newsFeed)?.shouldShow;
  const handleShow = () => {
    setShouldShow(Options.newsFeed, shouldShow);
  };
  return (
    <FormGroup sx={{ p: 0 }}>
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', p: 0 }}>
        <Text color={theme.customColors.setting}>{t('settings:news_widget')}</Text>
        <Stack direction="row" alignItems="center">
          <Switch checked={shouldShow} onChange={handleShow} />
        </Stack>
      </Stack>
    </FormGroup>
  );
};
