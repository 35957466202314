import { Checkbox, Stack, SxProps, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Text } from '../../../Text';
import { Todo } from './TodoWidget';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';

interface TodoProps {
  changeTodoList: (value: string) => void;
  todo: Todo[];
  changeChecked: (todo: Todo[]) => void;
  removeTodo: () => void;
  index: number;
  setTodoList: React.Dispatch<React.SetStateAction<Array<Todo[]>>>;
  todoList: Array<Todo[]>;
}

export const TodoList: React.FC<TodoProps> = ({
  changeTodoList,
  todo,
  changeChecked,
  removeTodo,
  setTodoList,
  index,
  todoList
}) => {
  const [value, setValue] = useState<string>('');
  const addTodo = (value: string) => {
    changeTodoList(value);
  };

  const handleChange = (index: number) => {
    const currentTodo = [...todo];
    const { isChecked } = currentTodo[index];
    currentTodo[index].isChecked = !isChecked;
    changeChecked(currentTodo);
  };

  const saveNewTodo = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!value) return;
    addTodo(value);
    setValue('');
  };

  const handleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const removeSpecificTodo = (currentIndex: number) => {
    const tempTodosList = [...todoList];
    const currentTodo = todo.filter((_, index) => index !== currentIndex);
    tempTodosList[index] = currentTodo;
    setTodoList(tempTodosList);
  };

  return (
    <Stack sx={container}>
      <form onSubmit={saveNewTodo}>
        <TextField type="text" sx={textFiled} name="todo" value={value} onChange={handleValue} />
      </form>
      {todo.map((note, index) => {
        const { isChecked } = note;
        return (
          <Stack
            key={index}
            sx={{
              flexDirection: 'row',
              overflowX: 'hidden',

              '&:hover > .removeTodo': {
                opacity: 1
              }
            }}
          >
            <CancelSharpIcon
              className="removeTodo"
              onClick={() => {
                removeSpecificTodo(index);
              }}
              sx={cancelIconTodo}
            />
            <Checkbox
              size="medium"
              sx={{ paddingBlock: '0' }}
              checked={isChecked}
              onChange={() => {
                handleChange(index);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Text sx={{ textDecoration: isChecked ? 'line-through' : 'none', wordBreak: 'break-word' }}>
              {note.text}
            </Text>
          </Stack>
        );
      })}
      <CancelSharpIcon className="removeTodoList" onClick={removeTodo} sx={cancelIcon} />
    </Stack>
  );
};

const container: SxProps = {
  '&:hover > .removeTodoList': {
    opacity: 1,
    '&:hover': {
      opacity: 1
    }
  }
};
const textFiled: SxProps = {
  width: '100%',
  padding: 0,
  mb: 3
};
const cancelIcon: SxProps = {
  position: 'absolute',
  right: '7px',
  opacity: '0',
  cursor: 'pointer',
  color: 'common.white',
  transition: '.15s opacity ease-in'
};
const cancelIconTodo: SxProps = {
  position: 'absolute',
  right: '7px',
  cursor: 'pointer',
  color: 'common.white',
  transition: '.15s opacity ease-in',
  opacity: '0'
};
