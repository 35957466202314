import React, { FC, useContext } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton } from '@mui/material';
import { GlobalContext } from '../../hooks/GlobalContext';
import { useTheme } from '@mui/material/styles';

interface SettingsButtonProps {
  isShow: boolean;
  settingsValue: number;
}

export const SettingsButton: FC<SettingsButtonProps> = ({ isShow, settingsValue }) => {
  const { setOpenSettingModal, setSettingsTabs } = useContext(GlobalContext);
  const theme = useTheme();

  const handleSettings = () => {
    setOpenSettingModal(true);
    setSettingsTabs(settingsValue);
  };

  return (
    <IconButton
      onClick={handleSettings}
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        py: '12px',
        px: '22px',
        opacity: isShow ? 1 : 0,
        transition: 'opacity 0.15s ease-in-out'
      }}
    >
      <SettingsOutlinedIcon sx={{ fontSize: theme.customComponents.text.size['lg'] }} />
    </IconButton>
  );
};
