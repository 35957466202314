import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { SettingsButton } from './SettingsButton';

interface SettingsWrapperProps {
  settingsValue: number;
  children: React.ReactElement;
}

export const SettingsWrapper: FC<SettingsWrapperProps> = ({ settingsValue, children }) => {
  const [shouldShowSettingsButton, setShouldShowSettingsButton] = useState<boolean>(false);

  return (
    <Stack
      sx={{ position: 'relative' }}
      onMouseEnter={() => setShouldShowSettingsButton(true)}
      onMouseLeave={() => setShouldShowSettingsButton(false)}
    >
      {children}
      <SettingsButton isShow={shouldShowSettingsButton} settingsValue={settingsValue} />
    </Stack>
  );
};
