import React, { FC } from 'react';
import { Text } from '../../Text';
import { IconButton, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateNewFolder from '@mui/icons-material/CreateNewFolderOutlined';
import { DialogLabels } from './Bookmarks';
import { useTranslation } from 'react-i18next';

interface BookmarksActionPanelProps {
  handleCreateDialogOpen: (type: DialogLabels) => void;
  withFolder?: boolean;
}

export const BookmarksActionPanel: FC<BookmarksActionPanelProps> = ({ handleCreateDialogOpen, withFolder = true }) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection="row" mb="25px" gap="0 16px" ml="16px">
      <Text size="lg">{t('bookmarks:bookmarksHeader')}</Text>

      <IconButton
        onClick={() => {
          handleCreateDialogOpen(DialogLabels.CREATE_BOOKMARK);
        }}
        aria-label={DialogLabels.CREATE_BOOKMARK}
      >
        <AddIcon />
      </IconButton>
      {withFolder && (
        <IconButton
          onClick={() => handleCreateDialogOpen(DialogLabels.CREATE_FOLDER)}
          aria-label={DialogLabels.CREATE_FOLDER}
        >
          <CreateNewFolder />
        </IconButton>
      )}
    </Stack>
  );
};
