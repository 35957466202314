import React, { FC } from 'react';
import { CustomDialog } from '../../CustomDialog';
import { BookmarkItem } from './BookmarkItem';
import { BookmarksProps, DialogLabels } from './Bookmarks';
import { List, ListItem, Stack } from '@mui/material';
import { BookmarksActionPanel } from './BookmarksActionPanel';

interface FoldersBookmarksProps {
  isOpen: boolean;
  folderName: string;
  bookmarks?: BookmarksProps['bookmarks'];
  handleClose: () => void;
  handleCreateDialogOpen: (type: DialogLabels) => void;
  handleDeleteBookmarkOrFolder: (id: string) => void;
  handleOpenUpdateDialog: (id: string) => void;
}

export const FoldersBookmarks: FC<FoldersBookmarksProps> = ({
  isOpen,
  folderName,
  bookmarks,
  handleClose,
  handleCreateDialogOpen,
  handleDeleteBookmarkOrFolder,
  handleOpenUpdateDialog
}) => (
  <CustomDialog isOpen={isOpen} title={folderName} handleClose={handleClose}>
    <Stack>
      <BookmarksActionPanel handleCreateDialogOpen={handleCreateDialogOpen} withFolder={false} />
      <List
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: '16px 0',
          py: 0
        }}
      >
        {bookmarks &&
          bookmarks.map(({ title, url, id }) => (
            <>
              {url && (
                <ListItem key={`${title}-${id}`} sx={{ p: 0, width: 'auto', justifyContent: 'center' }}>
                  <BookmarkItem
                    title={title}
                    url={url}
                    id={id}
                    handleDeleteBookmarkOrFolder={handleDeleteBookmarkOrFolder}
                    handleOpenUpdateDialog={handleOpenUpdateDialog}
                  />
                </ListItem>
              )}
            </>
          ))}
      </List>
    </Stack>
  </CustomDialog>
);
