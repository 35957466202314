import React, { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

interface CustomDialogProps {
  title?: string;
  isOpen: boolean;
  handleClose?: () => void;
  children: React.ReactElement;
  widthSize?: string | number;
}

export const CustomDialog: FC<CustomDialogProps> = ({ title, isOpen, handleClose, children, widthSize = 460 }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: widthSize,
          background: theme.customBackground.dialog,
          boxShadow: 1,
          borderRadius: '15px',
          backdropFilter: 'blur(20px)'
        }
      }}
      BackdropProps={{ sx: { backdropFilter: 'blur(6px)', backgroundColor: 'transparent' } }}
    >
      {title && (
        <DialogTitle
          sx={{
            color: 'inherit',
            fontSize: theme.customComponents.text.size['xxl'],
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pl: '40px'
          }}
        >
          {title}
          {handleClose ? (
            <IconButton aria-label="close" onClick={handleClose} sx={{ p: 0, color: 'inherit' }}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      <DialogContent sx={{ px: '40px' }}>{children}</DialogContent>
    </Dialog>
  );
};
