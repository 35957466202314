import React, { FC, useCallback, useMemo } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { DialogLabels } from './Bookmarks';
import FolderIcon from '@mui/icons-material/Folder';
import { useTranslation } from 'react-i18next';
import { Text } from '../../Text';
import { CustomDialog } from '../../CustomDialog';

interface CreateDialogProps {
  handleClose: () => void;
  isOpen: boolean;
  label: string;
  handleCreate: (values: { title: string; url: string | null }) => void;
  title: string;
  url: string | null;
  setUrl: (url: string | null) => void;
  setName: (title: string) => void;
  handleBookmarkUpdate: (values: { title: string; url: string | null }) => void;
}

interface Labels {
  [key: string]: string;
}

const inputStyles = {
  '& .MuiInputLabel-root.Mui-focused': { color: 'inherit' },
  '& .MuiInputBase-root': {
    '&:after': { borderBottom: '1px solid currentColor' },
    '&:before': { borderBottom: '1px solid currentColor' }
  },
  '& .MuiInputBase-input': { color: 'inherit' },
  '& .MuiFormLabel-root': { color: 'inherit' }
};

const CreateDialog: FC<CreateDialogProps> = ({
  handleClose,
  isOpen,
  label,
  handleCreate,
  title,
  url,
  setUrl,
  setName,
  handleBookmarkUpdate
}) => {
  const validUrlRegex = useMemo(() => /^(https:\/\/)/gm, []);

  const validUrl = useCallback(
    (url: string | null) => {
      if (!url) return null;
      if (!url.match(validUrlRegex)) {
        return `https://${url}`;
      } else {
        return url;
      }
    },
    [validUrlRegex]
  );

  const values = useMemo(() => ({ title, url: validUrl(url) }), [title, url, validUrl]);
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    DialogLabels.EDIT_BOOKMARK === label || DialogLabels.EDIT_FOLDER === label
      ? handleBookmarkUpdate(values)
      : handleCreate(values);
  };

  const labels: Labels = {
    createBookmark: t('createBookmarkDialog:createBookmarkLabel'),
    createFolder: t('createBookmarkDialog:createFolderLabel'),
    editBookmark: t('createBookmarkDialog:editBookmarkLabel'),
    editFolder: t('createBookmarkDialog:editFolderLabel')
  };

  return (
    <CustomDialog title={labels[label]} isOpen={isOpen} handleClose={handleClose}>
      <Stack flexDirection="row">
        <Stack
          sx={{
            width: 50,
            height: 50,
            backgroundColor: 'lightblue',
            borderRadius: '8px',
            boxShadow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            mt: '10px',
            mr: '20px'
          }}
        >
          {DialogLabels.CREATE_BOOKMARK === label ? (
            <Text sx={{ fontSize: 24, lineHeight: '50px', color: 'inherit' }}>{title?.split('')[0]}</Text>
          ) : (
            <FolderIcon fontSize="large" sx={{ color: 'secondary.main' }} />
          )}
        </Stack>
        <Stack component="form" onSubmit={handleSubmit} autoComplete="off" sx={{ flex: 1, gap: '24px', pr: '40px' }}>
          <TextField
            autoFocus
            id="name"
            label="Name"
            fullWidth
            type="text"
            variant="standard"
            value={title}
            required
            onChange={e => setName(e.target.value)}
            sx={inputStyles}
          />

          {(DialogLabels.CREATE_BOOKMARK === label || DialogLabels.EDIT_BOOKMARK === label) && (
            <TextField
              value={url}
              id="url"
              label="Url"
              fullWidth
              type="text"
              variant="standard"
              onChange={e => setUrl(e.target.value)}
              required
              sx={inputStyles}
            />
          )}
          <Stack sx={{ justifyContent: 'flex-end', pb: '20px', pt: '10px', gap: '24px', flexDirection: 'row' }}>
            <Button sx={{ textTransform: 'capitalize' }} variant="text" onClick={handleClose}>
              {t('createBookmarkDialog:cancelButton')}
            </Button>
            <Button type="submit" variant="text" sx={{ fontWeight: 700, textTransform: 'capitalize' }}>
              {t('createBookmarkDialog:saveButton')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </CustomDialog>
  );
};

export default CreateDialog;
