import { Button, SxProps } from '@mui/material';
import React from 'react';
import { Action } from './QuotesWidget';
import { ArrowForwardIosOutlined as NextArrowIcon } from '@mui/icons-material';
import { ArrowBackIosNewOutlined as BackArrowIcon } from '@mui/icons-material';

interface QuoteButtonProps {
  changeQuotes: (action: Action) => void;
  action: Action;
}

export const QuoteButton: React.FC<QuoteButtonProps> = ({ changeQuotes, action }) => (
  <Button
    variant="text"
    sx={buttonStyle}
    onClick={() => {
      changeQuotes(action);
    }}
  >
    {action === Action.prev ? <BackArrowIcon /> : <NextArrowIcon />}
  </Button>
);

const buttonStyle: SxProps = {
  maxWidth: 0,
  borderRadius: '99999px',
  color: 'text.primary',
  minWidth: 0,
  width: '26px',
  height: '26px'
};
