import './App.css';
import { GlobalStateProvider } from './hooks/GlobalContext';
import { Container } from './components/Container';
import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import React, { Suspense } from 'react';

const { REACT_APP_I18_API_KEY, REACT_APP_I18_BASE_PATH } = process.env;

const loadPath = `${REACT_APP_I18_BASE_PATH}${REACT_APP_I18_API_KEY}`;
i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: [
      'home',
      'todo',
      'note',
      'weather',
      'appsButtons',
      'appsMenu',
      'common',
      'newsFeed',
      'bookmarks',
      'popularSites',
      'createBookmarkDialog',
      'contextMenuText',
      'settings',
      'servicePanel'
    ],
    defaultNS: 'home',

    supportedLngs: ['en', 'sv', 'he'],

    backend: {
      loadPath: loadPath
    },
    react: {
      useSuspense: true
    }
  });
const App: React.FC = () => (
  <GlobalStateProvider>
    <Suspense>
      <Container />
    </Suspense>
  </GlobalStateProvider>
);

export default App;
