import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { SettingsOption } from '../SettingsModal';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface TabComponentProps {
  value: number;
  currentTabs: SettingsOption[];
  setSettingsTabs: React.Dispatch<React.SetStateAction<number>>;
}
export const TabComponent: React.FC<TabComponentProps> = ({ value, setSettingsTabs, currentTabs }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSettingsTabs(newValue);
  };

  return (
    <Tabs
      orientation="vertical"
      variant="standard"
      value={value}
      onChange={handleChange}
      sx={{ borderRight: 1, borderColor: 'divider', flex: 1 }}
    >
      {currentTabs.map((item, index) => {
        const label = t(`settings:${item}`);
        return (
          <Tab
            key={index}
            sx={{
              textTransform: 'capitalize',
              p: 0,
              minWidth: 0,
              minHeight: 0,
              alignItems: 'flex-start',
              paddingBlock: '10px',
              color: theme.customColors.setting
            }}
            label={label}
          />
        );
      })}
    </Tabs>
  );
};
