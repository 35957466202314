import Axios, { Method } from 'axios';
import { ApplicationData, getManifestData } from './manifest.service';
const applicationData: ApplicationData = getManifestData();
const axios = Axios.create({
  withCredentials: false,
  baseURL: applicationData.firstOffer.baseURL || '/'
});

export const httpService = {
  get(apiUrl: string, data: unknown) {
    return ajax(apiUrl, 'GET', data);
  },
  post(apiUrl: string, data: unknown) {
    return ajax(apiUrl, 'POST', data);
  },
  put(apiUrl: string, data: unknown) {
    return ajax(apiUrl, 'PUT', data);
  },
  delete(apiUrl: string, data: unknown) {
    return ajax(apiUrl, 'DELETE', data);
  }
};

async function ajax(apiUrl: string, method: Method = 'GET', data: unknown = null) {
  try {
    const res = await axios({
      url: `${apiUrl}`,
      method,
      data,
      params: method === 'GET' ? data : null
    });
    return res.data;
  } catch (err: unknown) {
    console.log(`Had Issues ${method}ing to the backend, endpoint: ${apiUrl}, with data: ${data}`);
    console.dir(err);
    throw err;
  }
}
