import { httpService } from './http.service';
import csv from 'csvtojson';
import camelcaseKeys from 'camelcase-keys';
interface StokeMarketValue {
  adjClose: string;
  close: string;
  date: string;
  high: string;
  low: string;
  open: string;
  volume: string;
}

const getSymbolData = async (symbol: string) => {
  const date = new Date();
  const start = new Date(date.getTime());
  start.setDate(date.getDate() - 7);
  start.setHours(0, 0, 0, 0);
  const end = new Date(date.getTime());
  end.setDate(date.getDate());
  end.setHours(0, 0, 0, 0);

  const startTimestamp = Math.round(start.valueOf() / 1000);
  const endTimestamp = Math.round(end.valueOf() / 1000);
  try {
    const result = await httpService.get(
      `/api/stocks/webiya?symbol=${symbol}&start=${startTimestamp}&end=${endTimestamp}`,
      null
    );
    return await convertCsvToObject(result, symbol);
  } catch (e: unknown) {
    return null;
  }
};

const convertCsvToObject = async (csvResult: string, symbol: string) => {
  const output: StokeMarketValue[] = camelcaseKeys(await csv().fromString(csvResult));
  const currentDay = output[output.length - 1];
  const prevDatData = output[output.length - 2];
  const result = ((+currentDay.close / +prevDatData.close) * 100 - 100) * 10;
  const isNegative = result < 0;
  const percent = result >= 0 ? result.toFixed(2) : (result * -1).toFixed(2);

  const stokeMarketData = {
    name: symbol,
    symbol,
    value: (+currentDay.close).toFixed(2),
    isNegative,
    percent,
    isShow: true
  };
  return stokeMarketData;
};
const get = async (stockMarketSymbol: string[]) => {
  const data = [];
  for (const stockSymbol of stockMarketSymbol) {
    const result = await getSymbolData(stockSymbol);
    if (result) data.push(result);
  }
  return data;
};
export const StokeMarketService = {
  get
};
