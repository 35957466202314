import React, { FC } from 'react';
import { Button, Tooltip } from '@mui/material';
import { PanelItems } from './ServicePanel';
import Zoom from '@mui/material/Zoom';

interface ServiceButtonProps {
  icon: PanelItems['icon'];
  label: PanelItems['label'];
  handleAction: (action: PanelItems['action']) => void;
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ServiceButton: FC<ServiceButtonProps> = ({ onClick, icon, label }) => (
  <Tooltip
    title={label}
    placement="right"
    TransitionProps={{ timeout: 200 }}
    TransitionComponent={Zoom}
    enterDelay={0}
    PopperProps={{
      sx: { '& .MuiTooltip-tooltip': { backgroundColor: 'transparent', color: 'primary.main', fontSize: 13, px: 0 } }
    }}
  >
    <Button
      sx={{
        '&:hover': { textDecoration: 'none', backgroundColor: 'transparent' },
        color: 'inherit',
        width: 'auto',
        minWidth: 'auto',
        p: '10px'
      }}
      variant="text"
      onClick={onClick}
    >
      {icon}
    </Button>
  </Tooltip>
);
