import React, { FC, useState } from 'react';
import { Menu, MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface ContextMenuProps {
  children: React.ReactElement;
  handleDeleteBookmarkOrFolder: (id: string) => void;
  id: string;
  handleOpenUpdateDialog: (id: string) => void;
}

export const ContextMenu: FC<ContextMenuProps> = ({
  children,
  handleDeleteBookmarkOrFolder,
  id,
  handleOpenUpdateDialog
}) => {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const theme = useTheme();

  const menuItemStyle = {
    pl: '7px',
    pr: '10px',
    py: '4px',
    fontSize: theme.customComponents.text.size['sm'],
    '&:hover': { backgroundColor: 'text.secondary' }
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleDelete = (id: string) => {
    handleDeleteBookmarkOrFolder(id);
    handleClose();
  };

  const handleUpdate = (id: string) => {
    handleOpenUpdateDialog(id);
    handleClose();
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Stack onContextMenu={handleContextMenu}>
      {children}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        MenuListProps={{ sx: { py: '6px' } }}
        PaperProps={{ sx: { background: theme.customBackground.contextMenu, borderRadius: '8px' } }}
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem sx={menuItemStyle} onClick={() => handleUpdate(id)}>
          Edit
        </MenuItem>
        <MenuItem sx={menuItemStyle} onClick={() => handleDelete(id)}>
          Delete
        </MenuItem>
      </Menu>
    </Stack>
  );
};
