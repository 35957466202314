import { Stack, SxProps } from '@mui/material';
import { Unit } from 'openweathermap-ts/dist/types';
import React, { useContext, useMemo } from 'react';
import { GlobalContext } from '../hooks/GlobalContext';
import { Widget } from '../services/manifest.service';
import { NewsFeed } from './widget/NewsFeed';
import { Notes } from './widget/Notes';
import { QuotesWidget } from './widget/QuotesWidget/QuotesWidget';
import { StockMarket } from './widget/StockMarketWidget/StockMarket';
import { TodoWidget } from './widget/WeatherWidget/Todo/TodoWidget';
import { Weather } from './widget/WeatherWidget/Weather';
import { SettingsWrapper } from './common/SettingsWrapper';

export enum Options {
  notes = 'notes',
  weather = 'weather',
  todo = 'todo',
  quotes = 'quotes',
  stockMarket = 'stock',
  newsFeed = 'news'
}

interface PanelProps {
  widgets: Widget[];
}

export const Panel: React.FC<PanelProps> = ({ widgets }) => {
  const { findSpecificWidget } = useContext(GlobalContext);
  const temperatureUnit = useMemo(() => {
    const weatherWidget = widgets.find(item => item.key === Options.weather);
    if (!weatherWidget) return;
    return weatherWidget.config.unit as Unit;
  }, [widgets]);

  const quotes = widgets.find(item => item.key === Options.quotes);

  const symbols = useMemo(() => {
    const [result] = widgets.filter(({ key }) => key === Options.stockMarket);
    return result?.config.symbol || [];
  }, [widgets]);

  const pathWidget = (key: string) => {
    if (!key) return;
    const result = findSpecificWidget(key);
    if (!result) return;
    const { shouldShow } = result;
    const widgetsOption = {
      [Options.notes]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={4}>
            <Notes />
          </SettingsWrapper>
        ),
      [Options.weather]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={2}>
            <Weather temperatureUnit={temperatureUnit} />
          </SettingsWrapper>
        ),
      [Options.todo]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={0}>
            <TodoWidget />
          </SettingsWrapper>
        ),
      [Options.quotes]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={0}>
            <QuotesWidget fontFamily={quotes?.config.fontFamily} />
          </SettingsWrapper>
        ),
      [Options.stockMarket]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={3}>
            <StockMarket symbols={symbols} />
          </SettingsWrapper>
        ),
      [Options.newsFeed]: () =>
        shouldShow && (
          <SettingsWrapper settingsValue={5}>
            <NewsFeed />
          </SettingsWrapper>
        )
    };
    return widgetsOption[key as Options];
  };

  return (
    <Stack sx={container}>
      {widgets.map(widget => {
        const functionComponent = pathWidget(widget.key);
        if (!functionComponent) return null;
        return <Stack key={widget.key}>{functionComponent()}</Stack>;
      })}
    </Stack>
  );
};

const container: SxProps = {
  gap: '21px',
  position: 'relative',
  overflow: 'overlay',
  height: 'calc(100vh - 300px)',
  paddingInline: '15px',
  transition: 'color .3s ease',
  width: '300px'
};
