import { Button, SxProps } from '@mui/material';
import React from 'react';
import { Text } from '../Text';
import { useTranslation } from 'react-i18next';

interface ToggleShowButtonProps {
  changeLength: (length: number) => void;
  amountOfItemsToShow: number;
  item: unknown[];
  DEFAULT_ITEMS_AMOUNT_TO_SHOW: number;
  textStyle: SxProps;
}

export const ToggleShowButton: React.FC<ToggleShowButtonProps> = ({
  changeLength,
  amountOfItemsToShow,
  item,
  DEFAULT_ITEMS_AMOUNT_TO_SHOW,
  textStyle
}) => {
  const { t } = useTranslation();

  const isReadMoreText = amountOfItemsToShow === DEFAULT_ITEMS_AMOUNT_TO_SHOW;

  return (
    <Button
      sx={{
        '&:hover': {
          backgroundColor: 'inherit'
        }
      }}
      disableRipple
      onClick={() => {
        changeLength(isReadMoreText ? item.length : DEFAULT_ITEMS_AMOUNT_TO_SHOW);
      }}
    >
      {isReadMoreText && (
        <Text sx={textStyle} size="sm" weight="low">
          {t('common:read_more')}
        </Text>
      )}
      {!isReadMoreText && (
        <Text sx={textStyle} size="sm" weight="low">
          {t('common:show_less')}
        </Text>
      )}
    </Button>
  );
};
