import { FontSize, FontWeight, TextType } from './index';
import {
  CustomTheme,
  CustomThemeOptions,
  Theme,
  ThemeModeConfig,
  ThemeOptions,
  ThemeConfig,
  SxProps,
  Shadows
} from '@mui/material/styles';

interface TextComponentTheme {
  type: TextType;
  size: FontSize;
  fontWeight: FontWeight;
}
interface StyleComponentTheme {
  filter: string;
}

declare module '@mui/material/styles' {
  interface CustomTheme extends Theme {
    customComponents: {
      text: TextComponentTheme;
    };
    customColors: {
      setting: string;
    };
    customBackground: {
      searchBar: string;
      folder: string;
      dialog: string;
      appsMenu: string;
      contextMenu: string;
    };
    icons: {
      arrowUp: {
        green: string;
        red: string;
      };
    };
    bullets: {
      selected: string;
      main: string;
    };
  }
  interface CustomThemeOptions extends ThemeOptions {
    customComponents: {
      text: TextComponentTheme;
      style: StyleComponentTheme;
    };

    icons: {
      arrowUp: {
        green: string;
        red: string;
      };
    };
    bullets: {
      selected: string;
      main: string;
    };
  }
  interface ThemeModeConfig extends ThemeOptions {
    customBackground: {
      searchBar: string;
      folder: string;
      dialog: string;
      appsMenu: string;
      contextMenu: string;
    };
    customColors: {
      setting: string;
    };
  }
  type ThemeConfig = ThemeModeConfig & CustomThemeOptions;

  export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

declare module '@mui/material/styles/useTheme' {
  export default function useTheme<T = CustomTheme>(): T;
}
const scrollbarBase: SxProps = {
  '& ::-webkit-scrollbar': {
    width: '5px',
    borderRadius: '5px',
    padding: '1px',
    marginLeft: '50px'
  },
  '& ::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    backgroundColor: 'rgba(128, 128, 128, 0)'
  }
};

const buttonCommonStyle = {
  color: 'inherit',
  width: 'auto',
  minWidth: 'auto',
  padding: 0
};

const baseTheme: CustomThemeOptions = {
  icons: {
    arrowUp: {
      red: '#FB0303',
      green: '#06BE0E'
    }
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    htmlFontSize: 16,
    fontWeightMedium: 500,
    fontSize: 14,
    h1: {
      fontSize: '60px',
      lineHeight: '70px'
    },
    h2: {
      fontSize: '48px',
      lineHeight: '56px'
    },
    h3: {
      fontSize: '40px',
      lineHeight: '56px'
    },
    h4: {
      fontSize: '32px',
      lineHeight: '56px'
    },
    h5: {
      fontSize: '26px',
      lineHeight: '56px'
    },
    h6: {
      fontSize: '18px',
      lineHeight: '56px'
    }
  },
  bullets: {
    selected:
      'linear-gradient(109.27deg, rgba(235, 234, 234, 0.82) 2.25%, rgba(240, 238, 238, 0.44) 54.92%, rgba(243, 240, 240, 0) 117.69%)',
    main: 'rgba(217, 217, 217, 0.26)'
  },
  customComponents: {
    style: {
      filter: 'drop-shadow(-2px 0px 10px rgba(203, 199, 199, 0.07))'
    },
    text: {
      type: {
        logo: 'Hadrey',
        default: 'Antarctica Typeface'
      },
      size: {
        xs: '10px',
        sm: '12px',
        md: '14px', // default
        lg: '16px',
        xl: '18px',
        xxl: '20px',
        xxxl: '36px',
        xxxxl: '50px'
      },
      fontWeight: {
        low: 300,
        light: 400,
        medium: 500,
        bold: 600
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1220,
      xl: 1536
    }
  }
};

const darkTheme: ThemeModeConfig = {
  customColors: {
    setting: 'rgba(255, 255, 255, 0.7)'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...scrollbarBase,
          '& :hover': {
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(128, 128, 128, .6)'
            }
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: buttonCommonStyle
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: buttonCommonStyle
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFFFFF',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B2B2B2'
    },
    action: {
      selected: '#000000',
      hover: 'rgba(255, 255, 255, 0.6)',
      disabled: '#9B9B9B'
    },
    background: {
      default: 'linear-gradient(107.85deg, rgba(196, 190, 190, 0.27) -8.1%, rgba(154, 153, 153, 0) 119.12%)'
    }
  },
  shadows: ['none', '-2px 0px 10px rgba(203, 199, 199, 0.07)', ...Array(25).fill('none')] as Shadows,
  customBackground: {
    searchBar:
      'linear-gradient(104.4deg, rgba(139, 139, 139, 0.38) 1.78%, rgba(180, 177, 177, 0.33) 45.79%, rgba(214, 211, 211, 0.51) 105.77%)',
    folder:
      'linear-gradient(103.91deg, rgba(253, 252, 252, 0.82) 1.73%, rgba(255, 253, 253, 0.44) 125.85%, rgba(255, 255, 255, 0) 273.77%)',
    dialog:
      'linear-gradient(132.54deg, rgba(139, 139, 139, 0.65) -14.76%, rgba(180, 177, 177, 0.69) 25.51%, rgba(194, 191, 191, 0.72) 94.35%, rgba(198, 195, 195, 0.72) 125.84%)',
    appsMenu:
      'linear-gradient(103.28deg, #EBEAEA 1.66%, rgba(240, 238, 238, 0.56) 65.18%, rgba(243, 240, 240, 0) 140.87%)',
    contextMenu:
      'linear-gradient(132.54deg, rgba(139, 139, 139, 0.65) -14.76%, rgba(180, 177, 177, 0.69) 25.51%, rgba(194, 191, 191, 0.72) 94.35%, rgba(198, 195, 195, 0.72) 125.84%)'
  }
};
const lightTheme: ThemeModeConfig = {
  customColors: {
    setting: 'rgba(0, 0, 0, 0.4)'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...scrollbarBase,

          '& :hover': {
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(243, 240, 240, .6)'
            }
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: buttonCommonStyle
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: buttonCommonStyle
      }
    }
  },
  palette: {
    mode: 'light',

    primary: {
      main: '#000000',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF'
    },
    text: {
      primary: '#000000',
      secondary: '#B2B2B2'
    },
    action: {
      hover: 'rgba(255, 255, 255, 0.6)',
      disabled: '#9B9B9B'
    },
    background: {
      default:
        'linear-gradient(109.11deg, #EBEAEA 2.24%, rgba(240, 238, 238, 0.56) 43.54%, rgba(243, 240, 240, 0) 92.76%)'
    }
  },
  shadows: ['none', '-2px 0px 10px rgba(203, 199, 199, 0.07)', ...Array(25).fill('none')] as Shadows,
  customBackground: {
    searchBar: 'linear-gradient(104.32deg, #EBEAEA 1.77%, rgba(240, 238, 238, 0.56) 34.68%, #F3F0F0 79.53%)',
    folder:
      'linear-gradient(103.91deg, rgba(253, 252, 252, 0.82) 1.73%, rgba(255, 253, 253, 0.44) 125.85%, rgba(255, 255, 255, 0) 273.77%)',
    dialog:
      'linear-gradient(111.25deg, #EBEAEA 2.44%, rgba(240, 238, 238, 0.56) 60.46%, rgba(243, 240, 240, 0) 129.6%)',
    appsMenu:
      'linear-gradient(103.28deg, #EBEAEA 1.66%, rgba(240, 238, 238, 0.56) 65.18%, rgba(243, 240, 240, 0) 140.87%)',
    contextMenu:
      'linear-gradient(139.75deg, #EBEAEA 4.71%, rgba(240, 238, 238, 0.78) 64.92%, rgba(243, 240, 240, 0.84) 136.66%)'
  }
};

export const themeConfig: Record<string, ThemeConfig> = {
  light: { ...baseTheme, ...lightTheme },
  dark: { ...baseTheme, ...darkTheme }
};
