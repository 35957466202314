import { CircularProgress, Stack } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { GlobalContext } from '../../hooks/GlobalContext';

import { NewsTab } from './components/NewsTab';

import { CustomDialog } from '../CustomDialog';
import { useEffect } from 'react';
import { useState } from 'react';
import { NoteTab } from './components/NoteTab';
import { WeatherTab } from './components/WeatherTab';
import { StockMarketTab } from './components/StockMarket/StockMarketTab';
import { Preferences } from './components/Preferences';
import { TabComponent } from './components/TabComponent';
import { PanelsTab } from './components/PanelsTab';
import { Options } from '../Panel';
import { Unit } from 'openweathermap-ts/dist/types';

export enum SettingsOption {
  weather = 'weather',
  stock = 'stock',
  news = 'news',
  notes = 'notes',
  preferences = 'preferences',
  panels = 'panels'
}

interface SettingsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DATE = 'date';
export const SettingsModal: React.FC<SettingsModalProps> = ({ open, setOpen }) => {
  const [currentTabs, setCurrentTabs] = useState<SettingsOption[]>([]);
  const { widgets, settingsTabs, setSettingsTabs } = useContext(GlobalContext);

  useEffect(() => {
    const tabsNames = [
      SettingsOption.preferences,
      SettingsOption.news,
      SettingsOption.weather,
      SettingsOption.notes,
      SettingsOption.stock,
      SettingsOption.panels,
      SettingsOption.stock
    ];
    const result: SettingsOption[] = [SettingsOption.preferences, SettingsOption.panels];
    widgets.map(widget => {
      if (tabsNames.includes(widget.key as SettingsOption)) result.push(widget.key as SettingsOption);
    });
    setCurrentTabs(result);
  }, [widgets]);

  const handleClose = () => {
    setOpen(false);
  };
  const temperatureUnit = useMemo(() => {
    const weatherWidget = widgets.find(item => item.key === Options.weather);
    if (!weatherWidget) return;
    return weatherWidget.config.unit as Unit;
  }, [widgets]);
  const is12HFormat = useMemo(() => {
    const weatherWidget = widgets.find(item => item.key === DATE);
    if (!weatherWidget) return;
    return weatherWidget.config.hour12;
  }, [widgets]);

  const symbols = useMemo(() => {
    const [result] = widgets.filter(({ key }) => key === Options.stockMarket);
    return result?.config.symbol || [];
  }, []);
  const dynamicTab = (key: string) => {
    const widgetsOption = {
      [SettingsOption.notes]: () => <NoteTab />,
      [SettingsOption.weather]: () => <WeatherTab temperatureUnit={temperatureUnit} />,
      [SettingsOption.stock]: () => <StockMarketTab symbols={symbols} />,
      [SettingsOption.news]: () => <NewsTab />,
      [SettingsOption.preferences]: () => <Preferences is12HFormat={is12HFormat} />,
      [SettingsOption.panels]: () => <PanelsTab />
    };
    return widgetsOption[key as SettingsOption];
  };
  if (!widgets) return <CircularProgress />;
  return (
    <CustomDialog widthSize="665px" isOpen={open} handleClose={handleClose} title="Settings">
      <Stack sx={{ flexGrow: 1, flexDirection: 'row', height: '400px' }}>
        <TabComponent value={settingsTabs} currentTabs={currentTabs} setSettingsTabs={setSettingsTabs} />
        <Stack sx={{ flex: 2 }}>
          {currentTabs.map((item, index) => {
            const functionComponent = dynamicTab(item);

            return (
              <Stack
                key={item}
                hidden={settingsTabs !== index}
                sx={{ paddingLeft: '37px', paddingRight: '37px', overflowY: 'scroll' }}
              >
                {settingsTabs === index && <Stack>{functionComponent()}</Stack>}
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </CustomDialog>
  );
};
