import { CircularProgress, Stack, SxProps } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { WeatherService } from '../../../services/weather.service';
import OpenWeatherMap from 'openweathermap-ts';
import { Unit, CurrentResponse } from 'openweathermap-ts/dist/types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Circle } from './trip_origin.svg';
import { useTheme } from '@mui/material/styles';
import { localStorageService } from '../../../services/storage.service';
import { StorageKeys } from '../../Container';
import { Text } from '../../Text';

interface LocationData {
  lat: number;
  lon: number;
}

enum TemperatureType {
  metric = 'C',
  imperial = 'F',
  standard = 'K'
}
interface WeatherStorage extends CurrentResponse {
  expire?: string;
}

interface WeatherProps {
  temperatureUnit: Unit | undefined;
}
export const Weather: React.FC<WeatherProps> = ({ temperatureUnit }) => {
  const [weather, setWeather] = useState<CurrentResponse | null>(null);
  const [locationData, setLocationData] = useState<LocationData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { REACT_APP_WEATHER_API_KEY } = process.env;

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const temperatureUnitIcon: Record<Unit, string> = {
    metric: TemperatureType.metric,
    imperial: TemperatureType.imperial,
    standard: TemperatureType.standard
  };
  const getLocationOfTheUser = async () => {
    const result = await WeatherService.getLocationOfTheUser();
    setLocationData(result as LocationData);
  };
  const getWeatherData = useCallback(async () => {
    if (!locationData || !temperatureUnit) return;
    const openWeather = new OpenWeatherMap({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      apiKey: REACT_APP_WEATHER_API_KEY!,
      language: i18n.language,
      units: temperatureUnit
    });
    const { lat, lon } = locationData;
    const result = await openWeather.getCurrentWeatherByGeoCoordinates(lat, lon);
    setWeather(result);
    const dateToStorage: WeatherStorage = { ...result };
    const oneHour = 60 * 60 * 1000;
    dateToStorage.expire = `${new Date()} + ${oneHour}`;
    localStorageService.set(StorageKeys.weather, dateToStorage);
  }, [i18n.language, locationData, temperatureUnit]);

  useEffect(() => {
    getLocationOfTheUser();
  }, []);

  useEffect(() => {
    getWeatherData();
    setIsLoading(false);
  }, [getWeatherData]);

  useEffect(() => {
    const weatherStorage = localStorageService.get(StorageKeys.weather);
    if (!weatherStorage?.expire || weather) return;
    const currentWeatherStorage = { ...weatherStorage };
    delete currentWeatherStorage.expire;
    setWeather(currentWeatherStorage);
  }, [weather]);

  if (isLoading) return <CircularProgress color="secondary" />;

  if (!weather?.weather || !temperatureUnit) return <Stack>{t('common:no_have_results')}</Stack>;

  return (
    <Stack sx={{ ...weatherContainer, background: theme.palette.background.default }}>
      <Stack sx={weatherStyle}>
        <Text size="xxl" sx={{ lineHeight: '1', mb: '16px' }}>
          {weather.name}
        </Text>
        <Text size="sm" textTransform="capitalize" sx={{ lineHeight: '1' }}>
          {weather.weather[0].description}
        </Text>
      </Stack>
      <Stack sx={{ flexDirection: 'row' }}>
        <Text size="xxxl" sx={{ lineHeight: '1' }}>
          {Math.round(weather.main.temp)}
        </Text>
        <Circle fill={theme.palette.text.primary} />
        <Stack justifyContent="flex-end">
          <Text size="xxl"> {temperatureUnitIcon[temperatureUnit]}</Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

const weatherContainer: SxProps = {
  flexDirection: 'row',
  alignItems: 'flex-start',
  borderRadius: '15px',
  WebkitBackdropFilter: 'blur(20px)',
  backdropFilter: 'blur(20px)',
  color: 'primary.main',
  justifyContent: 'space-between',
  pt: '28px ',
  pb: '48px',
  px: '22px',
  position: 'relative'
};
const weatherStyle: SxProps = {
  flexDirection: 'column',
  justifyContent: 'center',
  mr: '40px'
};
