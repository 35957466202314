import { CircularProgress, Link, Stack, SxProps } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Text } from '../../Text';
import { useTheme } from '@mui/material/styles';
import { ToggleShowButton } from '../../common/ToggleShowButton';
import { ReactComponent as Vector } from './assets/vector.svg';
import { StokeMarketService } from '../../../services/stockMarket.service';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { useTranslation } from 'react-i18next';

export interface Data {
  name: string;
  symbol: string;
  value: number;
  isNegative: boolean;
  percent: number;
  isShow: boolean;
}

const DEFAULT_ITEMS_AMOUNT_TO_SHOW = 5;

interface StockMarketProps {
  symbols: string[];
}

export const StockMarket: React.FC<StockMarketProps> = ({ symbols }) => {
  const { stockMarketData, setStockMarketData } = useContext(GlobalContext);
  const [amountOfItemsToShow, setAmountOfItemsToShow] = useState<number>(DEFAULT_ITEMS_AMOUNT_TO_SHOW);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const theme = useTheme();
  const { t } = useTranslation();
  const URL = 'https://finance.yahoo.com/quote/';
  const getStockMarketDataBySymbol = async () => {
    const result = await StokeMarketService.get(symbols);
    setIsLoading(false);
    setStockMarketData(result as unknown as Data[]);
  };

  useEffect(() => {
    getStockMarketDataBySymbol();
  }, []);

  if (isLoading) return <CircularProgress color="secondary" />;
  const haveResult = !stockMarketData.length || (!symbols && !isLoading);
  if (haveResult) return <Stack>{t('common:no_have_results')}</Stack>;
  const { green, red } = theme.icons.arrowUp;
  return (
    <Stack
      sx={{
        ...stokeMarketStyle,
        background: theme.palette.background.default
      }}
    >
      {stockMarketData.slice(0, amountOfItemsToShow).map((item, index) => {
        if (!item.isShow) return;

        return (
          <Link key={`${item}${index}`} href={`${URL}${item.symbol}`} sx={{ textDecoration: 'none' }}>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', paddingInline: '21px', mb: '8px' }}>
              <Stack>
                <Text size="lg">{item.name}</Text>
                <Text size="sm" weight="low">
                  {item.symbol}
                </Text>
              </Stack>
              <Stack>
                <Text size="lg">{item.value}</Text>
                <Stack sx={{ flexDirection: 'row', gap: '3px', justifyContent: 'flex-end' }}>
                  <Text size="sm" color={item.isNegative ? red : green}>
                    {item.percent}
                  </Text>
                  <Stack sx={{ mt: '4.6px', mb: '5.25px' }}>
                    <Vector fill={item.isNegative ? red : green} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Link>
        );
      })}
      <ToggleShowButton
        changeLength={setAmountOfItemsToShow}
        amountOfItemsToShow={amountOfItemsToShow}
        item={stockMarketData}
        DEFAULT_ITEMS_AMOUNT_TO_SHOW={DEFAULT_ITEMS_AMOUNT_TO_SHOW}
        textStyle={{ textAlign: 'center', mb: '15px' }}
      />
    </Stack>
  );
};
const stokeMarketStyle: SxProps = {
  resize: 'none',
  width: '100%',
  border: 'none',
  position: 'relative',
  backdropFilter: 'blur(20px)',
  borderRadius: '15px',
  paddingInline: '22px',
  paddingTop: '33px',
  paddingBottom: '12px'
};
