import React, { createContext, useEffect, useState } from 'react';
import { StorageKeys } from '../components/Container';
import { Data } from '../components/widget/StockMarketWidget/StockMarket';
import { ApplicationData, BackgroundImages, getManifestData, Widget } from '../services/manifest.service';
import { localStorageService } from '../services/storage.service';
import _ from 'lodash';
interface ShowToggleItem {
  key: string;
  text: string;
}
interface IGlobalState {
  widgets: Widget[];
  setWidgets: React.Dispatch<React.SetStateAction<Widget[]>>;
  stockMarketData: Data[] | never[];
  setStockMarketData: React.Dispatch<React.SetStateAction<Data[] | never[]>>;
  setIs12HFormat: React.Dispatch<React.SetStateAction<boolean>>;
  is12HFormat: boolean;
  backgrounds: BackgroundImages[];
  searchBarData: Widget | null;
  setSearchBarData: React.Dispatch<React.SetStateAction<Widget | null>>;
  notes: string[];
  openSettingModal: boolean;
  setNotes: React.Dispatch<React.SetStateAction<string[]>>;
  setShouldShow: (widgetName: string, shouldShow: boolean | undefined) => void;
  findSpecificWidget: (widgetName: string) => Widget | undefined;
  findSpecificWidgetIndex: (widgetName: string) => number | undefined;
  showToggleItems: ShowToggleItem[];
  settingsTabs: number;
  setSettingsTabs: React.Dispatch<React.SetStateAction<number>>;
  setOpenSettingModal: React.Dispatch<React.SetStateAction<boolean>>;
  saveWidgetToLocalStorage: (value: Widget[]) => void;
}

const initialState: IGlobalState = {
  widgets: [],
  setWidgets: () => {},
  stockMarketData: [],
  setStockMarketData: () => {},
  setIs12HFormat: () => {},
  is12HFormat: false,
  backgrounds: [],
  searchBarData: null,
  setSearchBarData: () => {},
  notes: [],
  setNotes: () => {},
  setSettingsTabs: () => {},
  settingsTabs: 0,
  setOpenSettingModal: () => {},
  openSettingModal: false,
  setShouldShow: () => {},
  findSpecificWidget: () => undefined,
  findSpecificWidgetIndex: () => undefined,
  showToggleItems: [],
  saveWidgetToLocalStorage: () => {}
};
export const GlobalContext = createContext<IGlobalState>(initialState);

interface GlobalStateProviderProps {
  children: React.ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const applicationData: ApplicationData = getManifestData();
  const [widgets, setWidgets] = useState<Widget[]>(applicationData.firstOffer.widgets);
  const [stockMarketData, setStockMarketData] = useState<Data[] | never[]>([]);
  const [notes, setNotes] = useState<string[]>([]);
  const [settingsTabs, setSettingsTabs] = React.useState(0);
  const backgrounds: BackgroundImages[] = applicationData.firstOffer.backgroundImages;
  const [openSettingModal, setOpenSettingModal] = useState<boolean>(false);

  const showToggleItems = [
    {
      key: 'todo',
      text: 'show_todo'
    },
    {
      key: 'quotes',
      text: 'show_quotes'
    },
    {
      key: 'bookmarks',
      text: 'show_bookmarks'
    },
    {
      key: 'popularSite',
      text: 'show_popular_sites'
    }
  ];

  const findSpecificWidgetIndex = (widgetName: string) => {
    const index = widgets.findIndex(({ key }) => key === widgetName);
    return index;
  };
  const findSpecificWidget = (widgetName: string) => {
    const [result] = widgets.filter(({ key }) => key === widgetName);
    return result;
  };

  const setShouldShow = (widgetName: string, shouldShow: boolean | undefined) => {
    if (shouldShow === undefined || !widgets) return;
    const index = findSpecificWidgetIndex(widgetName);
    if (index === undefined) return;
    const currentWidgets = [...widgets];
    currentWidgets[index].shouldShow = !shouldShow;
    saveWidgetToLocalStorage(currentWidgets);
  };

  const saveWidgetToLocalStorage = (value: Widget[]) => {
    localStorageService.set(StorageKeys.widgets, value);
    setWidgets([...value]);
  };

  useEffect(() => {
    const widgetsFromLocalStorage: Widget[] = localStorageService.get('widget');
    if (!widgetsFromLocalStorage) return;
    const currentWidgets = _.merge(widgets, widgetsFromLocalStorage);
    setWidgets(currentWidgets);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        ...initialState,
        saveWidgetToLocalStorage,
        widgets,
        findSpecificWidget,
        findSpecificWidgetIndex,
        setShouldShow,
        setWidgets,
        stockMarketData,
        setStockMarketData,
        notes,
        setNotes,
        backgrounds,
        showToggleItems,
        setSettingsTabs,
        settingsTabs,
        setOpenSettingModal,
        openSettingModal
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
