import { Button, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../hooks/GlobalContext';
import { PanelSide } from '../../Container';
import { Text } from '../../Text';

enum WidgetsNotForPanel {
  search = 'search',
  date = 'date',
  bookmarks = 'bookmarks',
  popularSite = 'popularSite'
}

export const PanelsTab: React.FC = () => {
  const { widgets, saveWidgetToLocalStorage } = useContext(GlobalContext);
  const { t } = useTranslation();

  const movePanel = (index: number, moveTo: PanelSide) => {
    const currentWidget = [...widgets];
    currentWidget[index].config.locationPanel = moveTo;
    saveWidgetToLocalStorage(currentWidget);
  };
  const widgetsNotForPanel = Object.values(WidgetsNotForPanel);
  const widgetsThatBelongToRightOrLeftPanel = widgets.filter(
    item => !widgetsNotForPanel.includes(item.key as WidgetsNotForPanel)
  );

  return (
    <Stack>
      {widgetsThatBelongToRightOrLeftPanel.map((item, index) => (
        <Stack key={index} direction="row" alignItems="center" gap="15px" justifyContent="space-between">
          <Text size="md">{item.name}</Text>
          <Stack direction="row" alignItems="center">
            <Button
              variant="text"
              sx={{ textTransform: 'capitalize' }}
              onClick={() => {
                const moveTo = item.config.locationPanel === PanelSide.left ? PanelSide.right : PanelSide.left;
                movePanel(index, moveTo);
              }}
            >
              {t(`settings:${item.config.locationPanel}`)}
            </Button>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
