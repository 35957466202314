import {
  Account,
  Google,
  Maps,
  Youtube,
  Play,
  News,
  Gmail,
  Contacts,
  Drive,
  Calendar,
  Translate,
  Photos,
  Docs,
  Blogger,
  Hangout,
  Keep,
  Jamboard,
  Earth,
  Collections
} from './appIcons';

interface AppsManifest {
  icon: string;
  label: string;
  url: string;
}

export const appsManifest: Array<AppsManifest[]> = [
  [
    { icon: Account, label: 'account', url: 'https://myaccount.google.com/' },
    { icon: Google, label: 'search', url: 'https://www.google.com/' },
    { icon: Maps, label: 'maps', url: 'https://maps.google.com/' },
    { icon: Youtube, label: 'youtube', url: 'https://www.youtube.com/' },
    { icon: Play, label: 'play', url: 'https://play.google.com/' },
    { icon: News, label: 'news', url: 'https://news.google.com/' },
    { icon: Gmail, label: 'gmail', url: 'https://mail.google.com/mail/' },
    { icon: Contacts, label: 'contacts', url: 'https://contacts.google.com/' },
    { icon: Drive, label: 'drive', url: 'https://drive.google.com/' },
    { icon: Calendar, label: 'calendar', url: 'https://www.google.com/calendar' },
    { icon: Translate, label: 'translate', url: 'https://translate.google.com/' },
    { icon: Photos, label: 'photos', url: 'https://photos.google.com/' }
  ],
  [
    { icon: Docs, label: 'docs', url: 'https://docs.google.com/document/' },
    { icon: Blogger, label: 'blogger', url: 'https://www.blogger.com/' },
    { icon: Hangout, label: 'hangout', url: 'https://hangouts.google.com/' },
    { icon: Keep, label: 'keep', url: 'https://keep.google.com/' },
    { icon: Jamboard, label: 'jamboard', url: 'https://jamboard.google.com/' },
    { icon: Earth, label: 'earth', url: 'https://earth.google.com/web/' },
    { icon: Collections, label: 'collections', url: 'https://www.google.com.ua/save' }
  ]
];
