import React, { useContext, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { Stack, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AddButton } from '../AddButton';
import { useTranslation } from 'react-i18next';
import { localStorageService } from '../../services/storage.service';
import { StorageKeys } from '../Container';
import { GlobalContext } from '../../hooks/GlobalContext';

export const Notes: React.FC = () => {
  const { notes, setNotes } = useContext(GlobalContext);
  const theme = useTheme();
  const { t } = useTranslation();

  const hasNotes = !!notes.length;

  const addNewNote = () => {
    setNotes([...notes, '']);
    localStorageService.set(StorageKeys.notes, notes);
  };

  const editNote = (index: number, value: string) => {
    const tempNotes = [...notes];
    tempNotes[index] = value;
    setNotes(tempNotes);
  };

  const removeNote = (currentIndex: number) => {
    const tempNotes = notes.filter((_, index) => index !== currentIndex);
    setNotes(tempNotes);
    localStorageService.set(StorageKeys.notes, tempNotes);
  };

  useEffect(() => {
    const notesStorage = localStorageService.get(StorageKeys.notes);
    if (!notesStorage || hasNotes) return;
    setNotes(notesStorage);
  }, [hasNotes]);

  useEffect(() => {
    if (!hasNotes) return;
    localStorageService.set(StorageKeys.notes, notes);
  }, [notes, hasNotes]);

  return (
    <Stack>
      {hasNotes && (
        <Stack sx={{ gap: '3px' }}>
          {notes.map((note, index) => {
            const isFirst = index === 0;
            return (
              <Stack
                key={index}
                sx={{
                  borderRadius: isFirst ? '15px 15px 0 0' : 0,
                  ...noteStyle,
                  background: theme.palette.background.default
                }}
              >
                <TextareaAutosize
                  cacheMeasurements
                  value={note}
                  autoFocus
                  maxLength={500}
                  onChange={event => {
                    editNote(index, event.target.value);
                  }}
                  minRows={2}
                  style={{ color: theme.palette.text.primary }}
                />
                <CancelSharpIcon
                  onClick={() => {
                    removeNote(index);
                  }}
                  sx={cancelIcon}
                />
              </Stack>
            );
          })}
        </Stack>
      )}

      <AddButton addAction={addNewNote} items={notes} text={t('note:add_note')} />
    </Stack>
  );
};

const noteStyle = {
  padding: '7px 10px',
  resize: 'none',
  width: '100%',
  border: 'none',
  position: 'relative',
  backdropFilter: 'blur(20px)',
  '&:hover > svg': {
    opacity: 0.5,
    '&:hover': {
      opacity: 1
    }
  },

  textarea: {
    resize: 'none',
    width: '100%',
    border: 'none',
    background: 'none',
    color: 'common.white',
    '&:focus-visible': {
      border: 'none',
      outline: 'none'
    },
    '&:focus ~ svg': {
      opacity: 1
    }
  }
};

const cancelIcon: SxProps = {
  position: 'absolute',
  right: '7px',
  opacity: '0',
  cursor: 'pointer',
  color: 'common.white',
  transition: '.15s opacity ease-in'
};
