import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontSize, FontWeight } from '../theme';
import { TypographyProps } from '@mui/material/Typography/Typography';

interface TextProps extends TypographyProps {
  size?: keyof FontSize;
  weight?: keyof FontWeight;
}

export const Text: FC<TextProps> = ({
  textTransform = 'none',
  size = 'md',
  weight = 'medium',
  children,
  color,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Typography
      fontSize={theme.customComponents.text.size[size]}
      fontWeight={theme.customComponents.text.fontWeight[weight]}
      fontFamily={theme.typography.fontFamily}
      color={color || theme.palette.text.primary}
      component={'span'}
      {...props}
      textTransform={textTransform}
    >
      {children}
    </Typography>
  );
};
