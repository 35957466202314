import React, { FC, useEffect, useState } from 'react';
import { Button, IconButton, List, ListItem, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '../Text';
import { PopularSitesService } from '../../services/popularSites.service';
import { localStorageService } from '../../services/storage.service';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material/styles';

interface PopularSitesData {
  brand: string;
  rurl: string;
  iurl: string;
}

export const PopularSites: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<string | null>(null);
  const [popularSites, setPopularSites] = useState<PopularSitesData[]>([]);
  const theme = useTheme();

  const getPopularSitesData = async () => {
    const result = await PopularSitesService.getPopularSites();
    localStorageService.set('popularSites', result.data);
  };

  const popularSitesList: PopularSitesData[] = localStorageService.get('popularSites');

  const handleHover = (url: string | null) => {
    setAnchorEl(url);
  };

  useEffect(() => {
    if (popularSitesList) return;
    getPopularSitesData();
  }, []);

  const handleDelete = (brandItem: string) => {
    const newPopularSitesArray: PopularSitesData[] = popularSites.filter(
      ({ brand }: { brand: string }) => brand !== brandItem
    );
    setPopularSites(newPopularSitesArray);
    localStorageService.set('popularSites', newPopularSitesArray);
  };

  useEffect(() => {
    if (!popularSitesList || popularSites.length) return;
    setPopularSites(popularSitesList);
  }, [popularSitesList, popularSites.length]);

  return (
    <Stack>
      <Stack flexDirection="row" mb="25px">
        <Text size="lg" sx={{ mr: '16px' }}>
          {t('popularSites:popularSitesHeader')}
        </Text>
      </Stack>
      {popularSites && (
        <List
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            alignItems: 'baseline',
            justifyContent: 'flex-start',
            gap: '20px',
            py: 0
          }}
        >
          {popularSites.map(({ brand, rurl: url, iurl: imgUrl }) => (
            <ListItem
              key={brand}
              sx={{ p: 0, width: 'auto', position: 'relative', color: 'primary.main' }}
              onMouseEnter={() => handleHover(brand)}
              onMouseLeave={() => handleHover(null)}
            >
              <IconButton
                onClick={() => handleDelete(brand)}
                sx={{
                  opacity: anchorEl === brand ? 1 : 0,
                  position: 'absolute',
                  top: 0,
                  right: '-50%',
                  transform: 'translateX(-100%)',
                  zIndex: 1,
                  boxShadow: 1,
                  backgroundColor: theme.customBackground.searchBar,
                  backdropFilter: 'blur(20px)',
                  p: '2px'
                }}
              >
                <RemoveIcon sx={{ fontSize: 12 }} />
              </IconButton>
              <Button href={url} title={brand}>
                <Stack
                  textAlign="center"
                  sx={{
                    backgroundImage: `url(${imgUrl})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: 47,
                    height: 47,
                    boxShadow: 1,
                    borderRadius: '8px'
                  }}
                />
              </Button>
            </ListItem>
          ))}
        </List>
      )}
    </Stack>
  );
};
