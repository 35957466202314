import { Stack, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { TodoList } from './TodoList';
import { AddButton } from '../../../AddButton';
import { useTranslation } from 'react-i18next';
import { localStorageService } from '../../../../services/storage.service';
import { StorageKeys } from '../../../Container';

export interface Todo {
  text: string;
  isChecked: boolean;
}
export const TodoWidget: React.FC = () => {
  const [todoList, setTodoList] = useState<Array<Todo[]>>([]);
  const theme = useTheme();
  const { t } = useTranslation();
  const addNewTodoBox = () => {
    setTodoList([...todoList, []]);
  };

  const removeTodo = (currentIndex: number) => {
    const tempTodoList = todoList.filter((_, index) => index !== currentIndex);
    setTodoList(tempTodoList);
    localStorageService.set(StorageKeys.todo, tempTodoList);
  };

  const changeTodoList = (value: string, index: number) => {
    const currentTodos = [...todoList];
    currentTodos[index].push({ text: value, isChecked: false });
    setTodoList(currentTodos);
    localStorageService.set(StorageKeys.todo, currentTodos);
  };
  const changeChecked = (todo: Todo[], index: number) => {
    const currentTodos = [...todoList];
    currentTodos[index] = todo;
    setTodoList(currentTodos);
    localStorageService.set(StorageKeys.todo, currentTodos);
  };

  useEffect(() => {
    const todoStorage = localStorageService.get(StorageKeys.todo);
    if (!todoStorage?.length || todoList.length) return;
    setTodoList(todoStorage);
  }, [todoList.length]);

  return (
    <Stack gap='15px'>
      {todoList.map((todo, index) => {
        const isFirst = index === 0;
        return (
          <Stack
            key={index}
            sx={{
              ...todoStyle,
              borderRadius: isFirst ? '10px 10px 0 0' : 0,
              gap: '3px',
              background: theme.palette.background.default
            }}
          >
            <TodoList
              changeTodoList={(value: string) => {
                changeTodoList(value, index);
              }}
              todo={todo}
              changeChecked={(todo: Todo[]) => {
                changeChecked(todo, index);
              }}
              removeTodo={() => {
                removeTodo(index);
              }}
              index={index}
              setTodoList={setTodoList}
              todoList={todoList}
            />
          </Stack>
        );
      })}
      <AddButton addAction={addNewTodoBox} items={todoList} text={t('todo:add_todo')} />
    </Stack>
  );
};

const todoStyle: SxProps = {
  padding: '7px 10px',
  resize: 'none',
  width: '100%',
  border: 'none',
  position: 'relative',
  filter: 'drop-shadow(-2px 0px 10px rgba(203, 199, 199, 0.07))',
  backdropFilter: 'blur(20px)'
};
